import { APIFilter } from '@/utils/api'

export default {
  async selectEmpleado (Vue, filter, search, sorter, page) {
    const apiFilter = new APIFilter()
      .addGT('estado', 0)
    if (search) {
      apiFilter.addNestedFilter(Vue.$online.empleado.searchFilter(search))
    }
    if (filter.esTecnico.value) {
      apiFilter.addExact(filter.esTecnico.field, filter.esTecnico.value)
    }
    if (filter.esComercial.value) {
      apiFilter.addExact(filter.esComercial.field, filter.esComercial.value)
    }
    if (filter.esVigilante.value) {
      apiFilter.addExact(filter.esVigilante.field, filter.esVigilante.value)
    }
    const resp = await Vue.$api.call(
      'empleado.select',
      {
        page,
        filter: apiFilter,
        sorter,
      },
    )
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectEmpleadoRows (Vue, pks) {
    const apiFilter = new APIFilter()
      .addIn('idempleado', pks)
    const resp = await Vue.$api.call('empleado.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
